import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import { PAGES } from '../../router/routes'
import RegularizacaoCiclosManager from '../../services/api/regularizacaoOpsTitulos/regularizacaoOPsTitulosManager'
import TipoLancamentoManager from '../../services/api/tipoLancamento/tipoLancamentoManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import {
  ObterOpsTituloPorPedidoResponse,
  OPPorPedidoResponse,
  RegularizacaoEfetivacaoCicloForm,
  TituloPorPedidoResponse,
  valorInicialRegularizacaoCiclos
} from '../../types/regularizacaoOPsTitulosTypes'
import { Sequencias } from '../../types/tipoLancamentoApiTypes'
import { regularizacaoCiclosEfetivacaoStyles } from './RegularizacaoCiclosEfetivacao.styles'
import { useConciliacaoContext } from '../../contexts/conciliacao-context'
import ciclosManager from '../../services/api/ciclos/ciclosManager'

export function regularizacaoCiclosEfetivacaoIO() {
  const dispatch = useDispatch()
  const { AtualizarPendenciasOpsTitulos } = useConciliacaoContext()
  const { pedidoId, clienteId } = useParams<{ pedidoId: string; clienteId: string }>()
  const [abrirModalEfetivacao, setAbrirModalEfetivacao] = useState(false)
  const [sequencias, setSequencias] = useState([] as Sequencias[])
  const [regularizacaoCiclos, setRegularizacaoCiclos] = useState<ObterOpsTituloPorPedidoResponse>(valorInicialRegularizacaoCiclos)
  const [bloquearBotaoConfirmarEfetivacao, setBloquearBotaoConfirmarEfetivacao] = useState<boolean>(true)
  const [opsSelecionados, setOpsSelecionados] = useState<OPPorPedidoResponse[]>([])
  const [titulosSelecionados, setTitulosSelecionados] = useState<TituloPorPedidoResponse[]>([])
  const [valorTotalOPsSelecionadas, setValorTotalOPsSelecionadas] = useState<number>(0)
  const [valorTotalTitulosSelecionados, setValorTotalTitulosSelecionados] = useState<number>(0)
  const rotas = useHistory()

  useEffect(() => {
    if (titulosSelecionados.length == 0 || opsSelecionados.length == 0) {
      setBloquearBotaoConfirmarEfetivacao(true)
    } else {
      setBloquearBotaoConfirmarEfetivacao(false)
    }
  }, [titulosSelecionados, opsSelecionados, bloquearBotaoConfirmarEfetivacao])

  const handleCheckboxTitulo = (event: React.ChangeEvent<HTMLInputElement>, tituloItem: TituloPorPedidoResponse) => {
    if (event.target.checked) {
      setTitulosSelecionados([...titulosSelecionados, tituloItem])
    } else {
      const filteredTitulosSelecionados = titulosSelecionados.filter((titulo) => titulo !== tituloItem)
      setTitulosSelecionados(filteredTitulosSelecionados)
    }
  }

  useEffect(() => {
    buscarCicloEfetivacao()
  }, [pedidoId])

  useEffect(() => {
    buscarSequencias()
    valorTotalOps()
    valorTotalTitulo()
  }, [titulosSelecionados, opsSelecionados])

  const handleCheckboxTodosTitulo = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setTitulosSelecionados(regularizacaoCiclos.titulos) : setTitulosSelecionados([])
  }

  const isSelectedTitulo = (titulo: TituloPorPedidoResponse) => titulosSelecionados.includes(titulo)

  const handleCheckboxOps = (event: React.ChangeEvent<HTMLInputElement>, opsItem: OPPorPedidoResponse) => {
    if (event.target.checked) {
      setOpsSelecionados([...opsSelecionados, opsItem])
    } else {
      const filteredTitulosSelecionados = opsSelecionados.filter((titulo) => titulo !== opsItem)
      setOpsSelecionados(filteredTitulosSelecionados)
    }
  }

  const handleCheckboxTodosOps = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setOpsSelecionados(regularizacaoCiclos.ops) : setOpsSelecionados([])
  }

  const isSelectedOps = (ops: OPPorPedidoResponse) => opsSelecionados.includes(ops)

  const valorTotalOps = () => {
    const valorFinalItenSelecionado = opsSelecionados.reduce((valor, acumulador) => {
      return valor + acumulador.valor
    }, 0)
    setValorTotalOPsSelecionadas(valorFinalItenSelecionado)
  }

  const valorTotalTitulo = () => {
    const valorFinalItemSelecionado = titulosSelecionados.reduce((valor, acumulador) => {
      return valor + acumulador.saldo
    }, 0)
    setValorTotalTitulosSelecionados(valorFinalItemSelecionado)
  }

  async function buscarSequencias() {
    const response: Sequencias[] = await TipoLancamentoManager.buscarSequencias('')
    setSequencias(response)
  }

  const handleAvaliarCondicoesRegularizacao = () => {
    const diferencaValorTotal = valorTotalOPsSelecionadas - valorTotalTitulosSelecionados
    if (diferencaValorTotal >= -1 && diferencaValorTotal <= 1) {
      ciclosManager.verificarProcessosEmExecucao()
      handleConfirmarEfetivacao({ sequenciaId: 0, acao: 0 })
    } else {
      setAbrirModalEfetivacao(true)
    }
  }

  const buscarCicloEfetivacao = () => {
    dispatch(loadingUpdate(true))
    RegularizacaoCiclosManager.buscarEfetivarRegularizacaoCiclos(pedidoId, clienteId)
      .then((response: ObterOpsTituloPorPedidoResponse) => {
        setRegularizacaoCiclos(response)
      })
      .catch(() => {
        toast.error(msgConstants.REGULARIZACAO.erroAoBuscarPendencias)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  const handleConfirmarEfetivacao = async (dataFormulario: RegularizacaoEfetivacaoCicloForm) => {
    dispatch(loadingUpdate(true))
    setAbrirModalEfetivacao(false)
    RegularizacaoCiclosManager.efetivarRegularizacaoCiclos({
      sequenciaId: dataFormulario.sequenciaId,
      ops: opsSelecionados.map((ops) => {
        return {
          bancoId: ops.banco,
          dataEmissao: ops.dataEmissao,
          operacaoOrdemPagamentoId: ops.operacaoOrdemPagamentoId,
          ordemPagamentoId: ops.ordemPagamentoId
        }
      }),
      titulos: titulosSelecionados.map((tituloSelecionado) => {
        return {
          codigoCliente: tituloSelecionado.codigoCliente,
          dataEmissao: tituloSelecionado.dataEmissao,
          filialId: tituloSelecionado.filialId,
          tipoTitulo: tituloSelecionado.tipoTitulo,
          tituloId: tituloSelecionado.tituloId
        }
      })
    })
      .then(() => {
        AtualizarPendenciasOpsTitulos(pedidoId)
        toast.success(msgConstants.REGULARIZACAO.sucessoRegularizacaoEfetivacao)
      })
      .catch((error: Error) => {
        toast.error(error.message)
      })
      .finally(() => {
        rotas.push(PAGES.informacaoId(null, null, null, null))
        dispatch(loadingReset())
      })
  }

  const limparTodosCheckbox = () => {
    setOpsSelecionados([])
    setTitulosSelecionados([])
  }

  return {
    styles: regularizacaoCiclosEfetivacaoStyles,
    abrirModalEfetivacao,
    setAbrirModalEfetivacao,
    handleConfirmarEfetivacao,
    sequencias,
    regularizacaoCiclos,
    handleCheckboxTitulo,
    isSelectedTitulo,
    handleCheckboxTodosTitulo,
    titulosSelecionados,
    handleCheckboxOps,
    handleCheckboxTodosOps,
    isSelectedOps,
    valorTotalOPsSelecionadas,
    valorTotalTitulosSelecionados,
    limparTodosCheckbox,
    opsSelecionados,
    bloquearBotaoConfirmarEfetivacao,
    handleAvaliarCondicoesRegularizacao
  }
}

export type RegularizacaoCiclosEfetivacaoIO = ReturnType<typeof regularizacaoCiclosEfetivacaoIO>
