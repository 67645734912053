import { useForm } from 'react-hook-form'
import { ModalRegularizarPerdasDebitoProps } from './ModalRegularizarPerdasDebito.View'
import debitosManager from '../../../../services/api/debitos/debitosManager'
import { EfetivarDebitoPerdaRequest } from '../../../../types/debitosTypes'
import { toast } from 'react-toastify'
import ciclosManager from '../../../../services/api/ciclos/ciclosManager'

export function modalRegularizarPerdasDebitoIO({ isClosed }: ModalRegularizarPerdasDebitoProps) {
  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { isSubmitting, isDirty }
  } = useForm<{ mesLancamento: string }>({
    mode: 'onChange',
    defaultValues: {
      mesLancamento: ''
    }
  })

  const fecharModal = () => {
    isClosed()
    setValue('mesLancamento', '')
  }

  const handleEfetivarDebitosPerda = async (data: { mesLancamento: string }) => {
    try {
      const [anoSelecionado, mesSelecionado] = data.mesLancamento.split('-').map(Number)
      const request: EfetivarDebitoPerdaRequest = {
        mesSelecionado,
        anoSelecionado
      }
      await ciclosManager.verificarProcessosEmExecucao()
      await debitosManager.efetivarDebitoPerda(request)
      fecharModal()
    } catch (error: any) {
      toast.error('Erro ao chamar regularização de débitos perda: ' + error.message)
    }
  }

  return {
    control,
    handleSubmit,
    fecharModal,
    handleEfetivarDebitosPerda,
    setValue,
    register,
    isSubmitting,
    isDirty
  }
}

export type ModalRegularizarPerdasDebitoIO = ReturnType<typeof modalRegularizarPerdasDebitoIO>
