import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import associacaoManager from '../../services/api/associacao/associacaoManager'
import ciclosManager from '../../services/api/ciclos/ciclosManager'
import conciliacaoManager, { default as validacaoManager } from '../../services/api/conciliacao/conciliacaoManager'
import transformacaoManager from '../../services/api/transformacao/transformacaoManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { CicloStatusGeral } from '../../types/ciclosApiType'
import {
  Contabilizacao,
  ContabilizacaoValores,
  Detalhes,
  DetalhesContabilizacao,
  ResponseDetalhesContabilizacao,
  ValidarCicloAjuste,
  ValoresContabilizacao
} from '../../types/conciliacaoApiTypes'
import { StatusProcessamento } from '../../types/genericoType'
import { CicloEtapaEnum, TipoLancamentoEnum } from '../../utils/enum'
import { iniciarCicloStyles } from './IniciarNovoCiclo.style'

const valorDefault = {
  internalizacao: [],
  ops: [],
  validacao: [],
  conciliacao: [],
  cicloId: 0,
  parceiroId: 0
}

const aguardandoProcessamento = [0, 100, 200, 300, 400, 500, 700, 800, 803, 999]
const LIMITE_MINIMO = 0
export function iniciarNovoCicloIO() {
  const dispatch = useDispatch()
  const { cicloId } = useParams<{ cicloId: string }>()
  const [statusAtualCiclo, setStatusAtualCiclo] = useState<CicloStatusGeral>(valorDefault)
  const [enableConfirmar, setEnableConfirmar] = useState(false)
  const [atualizarTabelaCiclo, setAtualizarTabelaCiclo] = useState(false)
  const [statusAtual, setStatusAtual] = useState<number>()
  const [opsSucesso, setOpsSucesso] = useState(false)
  const [modalCancelarNovoCiclo, setModalCancelarNovoCiclo] = useState(false)
  const [contabilizacaoValores, setContabilizacaoValores] = useState<ContabilizacaoValores>()
  const [ajustarValoresContabilizacao, setAjustarValoresContabilizacao] = useState<ContabilizacaoValores>({ credito: 0, debito: 0, diferenca: 0 })
  const [totalLinhasTabelaContabilizacao, setTotalLinhasTabelaContabilizacao] = useState(0)
  const [contabilizacaoLista, setContabilizacaoLista] = useState<Contabilizacao[]>()
  const [buscarValoresContabilizacao, setBuscarValoresContabilizacao] = useState('')
  const [modalFiltroDadosContabilizacao, setModalFiltroDadosContabilizacao] = useState(false)
  const [dadosDetalhesFiltrados, setDadosDetalhesFiltrados] = useState<Detalhes[]>([])
  const [gerarRelatorioContabilizacao, setGerarRelatorioContabilizacao] = useState<ValoresContabilizacao>({} as ValoresContabilizacao)
  const [valoresLiquidadosAbaixoLimite, setValoresLiquidadosAbaixoLimite] = useState<number>(0)
  const [modalConfirmacaoBaixoValor, setModalConfirmacaoBaixoValor] = useState<boolean>(false)
  const [disableCancelar, setDisableCancelar] = useState<boolean>(false)

  const handleFecharModal = () => {
    setModalFiltroDadosContabilizacao(false)
  }
  async function buscarContabilizacaoDetalhes(filter?: ResponseDetalhesContabilizacao) {
    dispatch(loadingUpdate(true))
    validacaoManager
      .buscarDetalhesContabilizacoes(cicloId, filter)
      .then((response: DetalhesContabilizacao) => {
        if (response.detalhes.length > 0) {
          setDadosDetalhesFiltrados(response.detalhes)
          setModalFiltroDadosContabilizacao(true)
        } else {
          toast.warning(msgConstants.PEDIDO_NAO_ENCONTRADO(String(filter?.pedidoPlanilhaId)))
        }
      })
      .catch(() => {
        toast.error(msgConstants.BUSCAR_DETALHES_CONTABILIZACAO_ERRO)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  const filtrarContabilizacoes = () => {
    if (buscarValoresContabilizacao !== '') {
      buscarContabilizacaoDetalhes({
        pedidoPlanilhaId: buscarValoresContabilizacao
      })
    }
  }
  const verificarValoresTitulosLimite = () => {
    const contabilizacoes = contabilizacaoLista
      ? contabilizacaoLista.filter((c) => c.tipoLancamento === TipoLancamentoEnum.baixaTitulo || c.tipoLancamento === TipoLancamentoEnum.baixaTituloParcial)
      : []
    const valor = contabilizacoes.reduce((soma, contabilizacaoAtual) => soma + contabilizacaoAtual.valor, 0)
    setValoresLiquidadosAbaixoLimite(valor)
  }

  async function buscarContabilizacoes(lancamento?: string, sequenciaId?: string) {
    conciliacaoManager
      .buscarContabilizacoes(cicloId, lancamento, sequenciaId)
      .then((response: ValoresContabilizacao) => {
        setContabilizacaoValores({ credito: response.credito, debito: response.debito, diferenca: response.diferenca })
        setAjustarValoresContabilizacao({ credito: response.credito, debito: response.debito, diferenca: response.diferenca })
        if (response.diferenca == 0) {
          setEnableConfirmar(true)
        }
        setGerarRelatorioContabilizacao(response)
        setContabilizacaoLista(response.contabilizacoes)
        setTotalLinhasTabelaContabilizacao(response.contabilizacoes.length)
        if (response.processamento !== null && response.processamento.status == StatusProcessamento.PROCESSANDO) {
          setTimeout(() => {
            buscarContabilizacoes(lancamento, sequenciaId)
          }, 10000)
        }
      })
      .catch(() => {
        toast.error(msgConstants.BUSCAR_TABELA_CONTABILIZACAO_ERRO)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  const gerarRelatorio = async () => {
    await conciliacaoManager
      .exportarRelatorioContabilizacao(cicloId)
      .then(() => {
        buscarContabilizacoes(cicloId)
      })
      .catch(() => {
        toast.error(msgConstants.USO_GENERICO.erroExportar)
      })
  }

  const buscarStatusCiclo = useCallback(async () => {
    await ciclosManager
      .buscarStatusCiclo(cicloId)
      .then((response: CicloStatusGeral) => {
        setEnableConfirmar(false)
        setStatusAtualCiclo(response)
        verificarStatusAtual(response)
      })
      .catch(() => {
        toast.error(msgConstants.CICLO_FLUXO.erroBuscarStatus)
      })
  }, [cicloId])

  const verificarStatusAtual = (statusCiclo: CicloStatusGeral) => {
    if (statusCiclo?.conciliacao.length > 0) {
      setStatusAtual(statusCiclo?.conciliacao[0].statusId)
    } else if (statusCiclo?.validacao.length > 0) {
      setStatusAtual(statusCiclo?.validacao[0].statusId)
    } else if (statusCiclo?.ops.length > 0) {
      setStatusAtual(statusCiclo?.ops[0].statusId)
    } else if (statusCiclo?.internalizacao.length > 0) {
      setStatusAtual(statusCiclo?.internalizacao[0].statusId)
    }
  }

  const validarProcessamento = useCallback(() => {
    let ultimoHistorico: any = {}
    if (statusAtualCiclo?.conciliacao.length > 0) {
      ultimoHistorico = statusAtualCiclo.conciliacao[0]
    } else if (statusAtualCiclo?.validacao.length > 0) {
      ultimoHistorico = statusAtualCiclo.validacao[0]
    } else if (statusAtualCiclo?.ops.length > 0) {
      ultimoHistorico = statusAtualCiclo.ops[0]
    } else if (statusAtualCiclo?.internalizacao.length > 0) {
      ultimoHistorico = statusAtualCiclo.internalizacao[0]
    }

    if (aguardandoProcessamento.includes(ultimoHistorico.statusId) && ultimoHistorico.statusTipo === 'WARNING') {
      setTimeout(buscarStatusCiclo, 10000)
    }
  }, [buscarStatusCiclo, statusAtualCiclo.conciliacao, statusAtualCiclo.internalizacao, statusAtualCiclo.ops, statusAtualCiclo.validacao])

  useEffect(() => {
    buscarStatusCiclo()
    setAtualizarTabelaCiclo((prev) => !prev)
  }, [buscarStatusCiclo])

  useEffect(() => {
    validarProcessamento()
    validarStatusParaCancelar()
  }, [statusAtualCiclo, validarProcessamento])

  useEffect(() => {
    verificarValoresTitulosLimite()
  }, [contabilizacaoLista])

  const updateStatus = () => {
    buscarStatusCiclo()
  }

  const finalizarEtapa = () => {
    if (statusAtualCiclo) {
      verificarStatusAtual(statusAtualCiclo)
    }
    switch (statusAtual) {
      case CicloEtapaEnum.uploadPlanilhaSucesso:
      case CicloEtapaEnum.transformacaoComErros:
        confirmarErroProcessamento()
        break
      case CicloEtapaEnum.planilhaTransformadaSucesso:
      case CicloEtapaEnum.associacoesComErros:
        confirmarAssociacao()
        break
      case CicloEtapaEnum.associacoesValidadasSucesso:
      case CicloEtapaEnum.itemsVariaveisErros:
        confirmarAssociacao()
        break
      case CicloEtapaEnum.itemsVariaveisSucesso:
      case CicloEtapaEnum.internalizandoCicloErros:
        concluirInternalizacao()
        break
      case CicloEtapaEnum.internalizandoCicloSucesso:
      case CicloEtapaEnum.vinculacaoOps:
      case CicloEtapaEnum.vinculacaoOpsErros:
      case CicloEtapaEnum.vinculacaoOpsSucesso:
      case CicloEtapaEnum.validacaoConciliacaoCicloErros:
        verificarCiclosEmAberto()
        break
      case CicloEtapaEnum.validacaoConciliacaoCicloSucesso:
        if (valoresLiquidadosAbaixoLimite <= LIMITE_MINIMO) {
          setModalConfirmacaoBaixoValor(true)
        } else {
          setModalCancelarNovoCiclo(true)
        }
        break
    }
  }

  const atualizarEtapa = () => {
    switch (statusAtual) {
      case CicloEtapaEnum.validacaoConciliacaoCicloErros:
      case CicloEtapaEnum.validacaoConciliacaoCicloSucesso:
      case CicloEtapaEnum.validacaoConciliacaoCicloErroInesperado:
        conferirValidacaoCiclo()
        break
      case CicloEtapaEnum.conciliacaoFinanceiraErros:
        setModalCancelarNovoCiclo(true)
        break
    }
  }

  function confirmarErroProcessamento() {
    dispatch(loadingUpdate(true))
    transformacaoManager
      .transformarPlanilha(cicloId)
      .then(() => {
        toast.success(msgConstants.INICIAR_CICLO.processar_sucesso)
      })
      .catch((e: Error) => {
        toast.error(e.message)
      })
      .finally(() => {
        finalizarRequisicao()
      })
  }

  const confirmarAssociacao = () => {
    dispatch(loadingUpdate(true))
    associacaoManager
      .aplicarAssociacaoCiclo(cicloId)
      .then(() => {
        toast.success(msgConstants.INICIAR_CICLO.processar_sucesso)
      })
      .catch((e: Error) => {
        toast.error(e.message)
      })
      .finally(() => {
        finalizarRequisicao()
      })
  }

  const concluirInternalizacao = () => {
    dispatch(loadingUpdate(true))
    ciclosManager
      .concluirInternalizacao(cicloId)
      .then(() => {
        toast.success(msgConstants.INICIAR_CICLO.internalizacao_sucesso)
      })
      .catch((e: Error) => {
        toast.error(e.message)
      })
      .finally(() => {
        finalizarRequisicao()
      })
  }

  const concluirValidacaoCiclo = () => {
    dispatch(loadingUpdate(true))
    conciliacaoManager
      .confirmarConciliacaoValidacao(cicloId)
      .then(() => {
        setModalCancelarNovoCiclo(false)
        toast.success(msgConstants.INICIAR_CICLO.validacao_ciclo_sucesso)
      })
      .catch((e: Error) => {
        toast.error(e.message)
      })
      .finally(() => {
        finalizarRequisicao()
      })
  }

  const conferirValidacaoCiclo = (validarConciliacao?: ValidarCicloAjuste) => {
    dispatch(loadingUpdate(true))
    setOpsSucesso(false)
    if (!validarConciliacao) {
      validarConciliacao = {
        ajuste: 0,
        tipoAjuste: 'C',
        sequenciaId: 0
      }
    }
    conciliacaoManager
      .validarConciliacaoCiclo(cicloId, validarConciliacao)
      .then(() => {
        toast.success(msgConstants.INICIAR_CICLO.validacao_ciclo_sucesso)
      })
      .catch((e: Error) => {
        toast.error(e.message)
      })
      .finally(() => {
        finalizarRequisicao()
      })
  }

  function finalizarRequisicao() {
    setTimeout(() => {
      dispatch(loadingReset())
      updateStatus()
      setEnableConfirmar(false)
    }, 2000)
  }

  const verificarStatusVinculacaoOPs = () => {
    let isStatusVinculacaoOPs = false

    if (
      statusAtual === CicloEtapaEnum.internalizandoCicloSucesso ||
      statusAtual === CicloEtapaEnum.vinculacaoOps ||
      statusAtual === CicloEtapaEnum.vinculacaoOpsErros
    ) {
      isStatusVinculacaoOPs = true
    }

    return isStatusVinculacaoOPs
  }

  const verificarStatusValidacao = () => {
    let isStatusValidacao = false

    if (statusAtual === CicloEtapaEnum.validacaoConciliacaoCicloSucesso || statusAtual === CicloEtapaEnum.validacaoConciliacaoCicloErros) {
      isStatusValidacao = true
    }

    return isStatusValidacao
  }

  const submeterFrase = (frase: string) => {
    dispatch(loadingUpdate(true))
    conciliacaoManager
      .validacaoFraseConfirmacaoCiclo(cicloId, frase)
      .then(() => {
        toast.success(msgConstants.INICIAR_CICLO.sucesso_validar_abaixo_limite)
        setEnableConfirmar(false)
        setDisableCancelar(true)
        setModalConfirmacaoBaixoValor(false)
        concluirValidacaoCiclo()
        finalizarRequisicao()
      })
      .catch((e: Error) => {
        dispatch(loadingReset())
        toast.error(e.message)
      })
  }

  const verificarCiclosEmAberto = () => {
    dispatch(loadingUpdate(true))
    ciclosManager
      .verificarProcessosEmExecucao(cicloId)
      .then(() => {
        dispatch(loadingReset())
        updateStatus()
        conferirValidacaoCiclo()
      })
      .catch((e: Error) => {
        toast.error(e.message)
        finalizarRequisicao()
      })
  }

  const validarStatusParaCancelar = (): void => {
    const statusVerificado = statusAtual ?? CicloEtapaEnum.conciliacaoFinanceiraErros
    const deveDesabilitar = statusVerificado !== CicloEtapaEnum.conciliacaoFinanceiraErros && statusVerificado >= CicloEtapaEnum.conciliacaoFinanceira
    setDisableCancelar(deveDesabilitar)
  }

  return {
    styles: iniciarCicloStyles,
    cicloId,
    statusAtualCiclo,
    enableConfirmar,
    opsSucesso,
    updateStatus,
    statusAtual,
    finalizarEtapa,
    atualizarEtapa,
    setEnableConfirmar,
    totalLinhasTabelaContabilizacao,
    modalCancelarNovoCiclo,
    setModalCancelarNovoCiclo,
    concluirValidacaoCiclo,
    buscarContabilizacoes,
    verificarStatusValidacao,
    verificarStatusVinculacaoOPs,
    updateTable: atualizarTabelaCiclo,
    conferirValidacaoCiclo,
    contabilizacaoValores,
    setContabilizacaoValores,
    ajustarValoresContabilizacao,
    contabilizacaoLista,
    setOpsSucesso,
    buscarValoresContabilizacao,
    setBuscarValoresContabilizacao,
    filtrarContabilizacoes,
    handleFecharModal,
    modalFiltroDadosContabilizacao,
    dadosDetalhesFiltrados,
    setDadosDetalhesFiltrados,
    gerarRelatorio,
    gerarRelatorioContabilizacao,
    modalConfirmacaoBaixoValor,
    setModalConfirmacaoBaixoValor,
    submeterFrase,
    disableCancelar,
    verificarCiclosEmAberto
  }
}

export type IniciarNovoCicloIO = ReturnType<typeof iniciarNovoCicloIO>
