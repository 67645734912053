import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import msgConstants from '../../constants/msgConstants'
import RegularizacaoTitulosManager from '../../services/api/regularizacaoTitulos/regularizacaoTitulosManager'
import { loadingReset, loadingUpdate } from '../../store/actions/loadingAction'
import { ResponsePaginado, StatusProcessamento } from '../../types/genericoType'
import {
  HistoricoRegularizacao,
  ValidacaoPlanilhaResponse,
  valorDefaultHistoricoRegularizacao,
  valorDefaultModalValidado
} from '../../types/regularizacaoTypes'
import { Paginacao } from '../../types/tabelaTypes'
import { regularizacaoTitulosStyle } from './RegularizacaoTitulos.style'
import ciclosManager from '../../services/api/ciclos/ciclosManager'

export function regularizacaoTitulosIO() {
  const [historicosRegularizacao, setHistoricosRegularizacao] = useState<ResponsePaginado<HistoricoRegularizacao>>(valorDefaultHistoricoRegularizacao)
  const [abrirModalValidarPlanilha, setAbrirModalValidarPlanilha] = useState<boolean>(false)
  const [informacaoValidadaModal, setInformacaoValidadaModal] = useState<ValidacaoPlanilhaResponse>(valorDefaultModalValidado)
  const [mensagemErroUploadPlanilha, setMensagemErroUploadPlanilha] = useState<string>('')
  const [arquivoId, setArquivoId] = useState<string>('')
  const dispatch = useDispatch()
  const history = useHistory()
  const [valorSetTimeOut, setValorSetTimeOut] = useState<number>(0)

  useEffect(() => {
    atualizarHistoricoRegularizacaoTitulos({ pageNumber: 1, pageSize: 5 })
  }, [])

  useEffect(() => {
    return history.listen(() => {
      clearTimeout(valorSetTimeOut)
    })
  }, [valorSetTimeOut])

  const validarPlanilha = (arquivo?: FormData) => {
    if (arquivo) {
      dispatch(loadingUpdate(true))
      setMensagemErroUploadPlanilha('')
      RegularizacaoTitulosManager.validarPlanilhaRegularizacaoTitulos(arquivo)
        .then((response: ValidacaoPlanilhaResponse) => {
          setInformacaoValidadaModal(response)
        })
        .catch((e: Error) => {
          setMensagemErroUploadPlanilha(e.message)
        })
        .finally(() => {
          dispatch(loadingReset())
          setAbrirModalValidarPlanilha(true)
        })
    } else {
      toast.warning(msgConstants.REGULARIZACAO.inserirArquivo)
    }
  }

  const handleFecharModalValidarPlanilha = () => {
    setAbrirModalValidarPlanilha(false)
  }

  const handleAbrirModalValidarPlanilha = () => {
    setAbrirModalValidarPlanilha(true)
  }

  async function efetivarRegularizacaoTitulos() {
    dispatch(loadingUpdate(true))
    ciclosManager
      .verificarProcessosEmExecucao()
      .then(() => {
        return RegularizacaoTitulosManager.efetivarRegularizacaoTitulos(arquivoId)
      })
      .then(() => {
        setAbrirModalValidarPlanilha(false)
        atualizarHistoricoRegularizacaoTitulos({ pageNumber: 1, pageSize: 5 })
      })
      .catch((e) => {
        toast.error(e.message)
      })
      .finally(() => {
        dispatch(loadingReset())
      })
  }

  async function atualizarHistoricoRegularizacaoTitulos(paginacao: Paginacao, desativarCarregamentoTela?: boolean) {
    !desativarCarregamentoTela && dispatch(loadingUpdate(true))

    RegularizacaoTitulosManager.buscarHistoricoRegularizacaoTitulos({ pageNumber: paginacao.pageNumber, pageSize: paginacao.pageSize })
      .then((response: ResponsePaginado<HistoricoRegularizacao>) => {
        const statusRelatorio = response.itens.map((processamento) => processamento.processamento.status)
        setHistoricosRegularizacao(response)
        if (statusRelatorio !== null && statusRelatorio.includes(StatusProcessamento.PROCESSANDO)) {
          const atualizarStatus = setTimeout(() => {
            atualizarHistoricoRegularizacaoTitulos(
              {
                pageNumber: paginacao.pageNumber,
                pageSize: paginacao.pageSize
              },
              true
            )
          }, 10000)
          setValorSetTimeOut(Number(atualizarStatus))
        }
      })
      .catch(() => {
        toast.error(msgConstants.REGULARIZACAO.erroAoBuscarPendencias)
      })
      .finally(() => {
        !desativarCarregamentoTela && dispatch(loadingReset())
      })
  }

  return {
    styles: regularizacaoTitulosStyle,
    validarPlanilha,
    historicosRegularizacao,
    totalLinhas: historicosRegularizacao.totalItens,
    handleFecharModalValidarPlanilha,
    handleAbrirModalValidarPlanilha,
    abrirModalValidarPlanilha,
    informacaoValidadaModal,
    mensagemErroUploadPlanilha,
    atualizarHistoricoRegularizacaoTitulos,
    efetivarRegularizacaoTitulos,
    setArquivoId,
    valorSetTimeOut
  }
}

export type RegularizacaoTitulosIO = ReturnType<typeof regularizacaoTitulosIO>
